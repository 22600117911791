.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.container {
  max-width: 1024px;
  margin:  0 auto;
}

.cardsWrap {
  display: flex;
  flex-flow: wrap;
  // align-items: center;
  justify-content: left;
}
.card {
  margin: 5px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 200px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.5);
  padding: 15px;
  border-radius: 8px;
  text-decoration: none;
  @media only screen and (max-width: 420px) {
    width: 100%;
  }
  &.active {
    box-shadow: 0 0 8px 4px rgb(37 213 81 / 80%);
    button {
      background-color: #282c34;
      color: #fff;
    }
  }
  &:hover {
    button {
      background-color: #282c34;
      color: #fff;
    }
  }
  > span {
    max-width: 140px;
    height: 140px;
    display: block;
    margin: 0 auto;
    > img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  > p {
    color: #000;
    margin-top: auto;
  }
  button {
    cursor: pointer;
    font-weight: bold;
    margin-top: auto;
    &:hover {
      background-color: #282c34;
      color: #fff;
    }
    &:active {
      transform: scale(0.9);
    }
  }
}

.capital {
  text-transform: capitalize;
  position: sticky;
  top: 0;
  background: #f8f8f8;
  text-align: left;
  padding: 5px 10px;
  margin: 5px 0 0 0;
}

.copyIcon {
  font-size: 18px;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  &:active {
    transform: scale(0.9);
  }
}
